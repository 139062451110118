/* Custom Scrollbar Styling */
.scrollbar {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #E33B76 #E5E5E5; /* For Firefox */
    border-radius: 10px;

  }
  
  .scrollbar::-webkit-scrollbar {
    width: 8px; /* Adjust width */
    border-radius: 10px;
  }
  
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #E33B76; /* Pink color */
    border-radius: 10px;
    height: 138px; /* Set thumb height */
  }
  
  .scrollbar::-webkit-scrollbar-track {
    background: #E5E5E5; /* Light gray track */
    border-radius: 10px;
  }
  
  /* Ensure tasks do not overflow horizontally */
  .task-container {
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scroll */
    border-radius: 10px;

  }